import * as React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";
import { Navbar } from "react-bootstrap";

import logo_white from "../../static/schlafgut_schlafberatung_logo_2023_black.png";
import facebook from "../images/icon_facebook.png";
import instagram from "../images/icon_instagram2.png";

const Layout = ({ location, title, children }) => {
  return (
    <>
      <div className="main-wrapper" style={{ minHeight: "78%" }}>
        <header className="d-none d-sm-block" style={{ marginBottom: 0 }}>
          <Navbar
            expand="lg"
            style={{ transition: "1s ease", padding: 0, background: "#fff" }}
          >
            <div className="global-wrapper navbar navbar-expand-lg">
              <Navbar.Brand href="https://schlafgut-schlafberatung.de/">
                <img
                  src={logo_white}
                  title={title}
                  alt={title}
                  height="80"
                  width="210"
                  className="logo"
                />
              </Navbar.Brand>
            </div>
          </Navbar>
        </header>
        {children}
        <footer>
          <div
            style={{ padding: 20, marginTop: -10, marginBottom: -10 }}
            className="global-wrapper text-center text-sm-start"
          >
            <div className="row">
              <div className="col-sm-6 paddingIfSmall">
                <Link
                  target="_blank"
                  to="https://www.instagram.com/schlafgut_schlafberatung/"
                >
                  Blog
                </Link>{" "}
                &nbsp;&nbsp;
                <Link
                  target="_blank"
                  to="https://schlafgut-schlafberatung.de/impressum"
                >
                  Impressum
                </Link>
                &nbsp;&nbsp;
                <Link
                  target="_blank"
                  to="https://schlafgut-schlafberatung.de/schlafpakete/"
                >
                  Preise
                </Link>
                &nbsp;&nbsp;
                <Link
                  target="_blank"
                  to="https://schlafgut-schlafberatung.de/kontakt"
                >
                  Kontakt
                </Link>
                <br />
                <br />
                <a
                  href="https://www.facebook.com/sandy.lein.904"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    style={{ verticalAlign: "sub" }}
                    src={facebook}
                    alt="facebook"
                    width="20"
                    height="20"
                  />{" "}
                  Facebook
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://www.instagram.com/schlafgut_schlafberatung/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    style={{ verticalAlign: "sub" }}
                    src={instagram}
                    alt="Instagram"
                    width="20"
                    height="20"
                  />{" "}
                  Instagram
                </a>
              </div>
              <div className="col-sm-6 text-center text-sm-end">
                {new Date().getFullYear()} © <b>Sandy Palmer</b>
                <br />
                💖 hallo@schlafgut-schlafberatung.de
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div style={{ position: "fixed", right: 15, bottom: 15, zIndex: 999 }}>

        <a
          href="https://www.instagram.com/schlafgut_schlafberatung/"
          target="_blank"
          rel="noreferrer"
          title="Instagram schlafgut_schlafberatung"
        >
          <div className="instaCss" style={{ marginBottom: 10 }}></div>
        </a>

        <a
          href="https://wa.me/491776256528"
          target="_blank"
          rel="noreferrer"
          title="WhatsApp Sandy Palmer"
        >
          <div className="social_Whatsapp"></div>
        </a>

      </div>
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText="Erlauben"
        style={{
          position: "fixed",
          color: "#fff",
          opacity: 0.9,
          fontSize: 16,
          background: "#548f9f",
          padding: 40,
          margin: 0,
          bottom: 0,
        }}
        buttonClasses="btn btn-light"
        containerClasses="col-12 text-center"
      >
        Meine Webseite verwendet <b>Cookies</b>. Diese benötige ich
        beispielsweise für die Erfassung der Besucher bei Google Analytics.
        <br />
        <a
          href="/datenschutzerklaerung"
          title="Datenschutzerklärung"
          className="btn btn-cookie"
        >
          Datenschutzerklärung
        </a>
        <br />
        <br />
      </CookieConsent>
    </>
  );
};

export default Layout;
